<template>
  <CCard body-wrapper>
    <CRow align-vertical="center" class="mb-3">
      <CCol col="auto">
        <h2>Транзиты</h2>
      </CCol>
      <CCol col="auto">
        <CButton
          :variant="isFiltersVisible ? null : 'outline'"
          color="dark"
          @click="isFiltersVisible = !isFiltersVisible"
        >
          Фильтр
        </CButton>
      </CCol>
    </CRow>
    <CForm
      v-show="isFiltersVisible"
      class="filters mb-3 p-3"
      @submit.prevent="loadTransits(1)"
    >
    <CRow align-vertical="center">
      <CCol col="12">
        <div class="filters__filter">
          <loading-button :loading="loading" text="Найти" />
          <CButton
            class="ml-2"
            color="secondary"
            style="width: 90px;"
            @click="clearFilters"
          >
            Очистить
          </CButton>
        </div>
      </CCol>
      <CCol xs="12" xl="4">
        <div
          class="filters__filter"
          :class="{ 'filters__filter--active': filters.sku }"
        >
          <CInput
            label="Артикул"
            :value.sync="filters.sku"
          />
        </div>
      </CCol>
      <CCol xs="12" xl="4">
        <div
          class="filters__filter"
          :class="{ 'filters__filter--active': filters.initiator }"
        >
          <CInput
            label="Поиск по документу"
            :value.sync="filters.initiator"
          />
        </div>
      </CCol>
      <CCol
        xs="12"
        xl="4"
      >
        <div
          class="filters__filter"
          :class="{ 'filters__filter--active': filters.verified != null }"
        >
          <CSelect
            :options="[{value: null, label: 'Все'}, {value: true, label: 'Без расхождений'}, {value: false, label: 'С расхождениями'}]"
            label="Расхождения"
            :value.sync="filters.verified"
          />
        </div>
      </CCol>
      <CCol xs="12" xl="4">
        <div class="filters__filter" :class="{ 'filters__filter--active': filters.ownershipFrom }">
          <div class="form-group">
            <label>В транзите с</label>
            <date-picker
              type="date"
              v-model="filters.ownershipFrom"
              clearable
            />
          </div>
        </div>
      </CCol>
    </CRow>
    </CForm>
    <CDataTable
      :items="listForTable"
      :fields="tableFields"
      hover
      striped
      :loading="loading"
     >
     <template #loading>
       <CElementCover
         :opacity="0.8"
       >
         <CSpinner
           class="custom-spinner"
           color="info"
         />
       </CElementCover>
     </template>
     <template #no-items-view>
       <div class="text-center">
         Ничего не найдено
       </div>
     </template>
     <template #initiators="{ item }">
       <td class="align-middle">
         <span>{{item.initiators.map((el) => el.number).join(', ')}}</span>
       </td>
     </template>
     <template #productOwner="{ item }">
       <td class="align-middle">
         <span>{{item.lastTransactions.map((el) => el.productOwner.name).join(', ')}}</span>
       </td>
     </template>
     <template #daysInTransit="{ item }">
       <td class="align-middle">
         <span>{{item.daysInTransit}} дн.</span>
       </td>
     </template>
     <template #details="{item}">
      <CCollapse :show="Boolean(item._toggled)" :duration="200">
        <CCardBody>
        </CCardBody>
      </CCollapse>
    </template>
     <template #actions="{ item }">
       <td class="align-middle text-right">
         <CButton
           class="mr-2"
           color="secondary"
           title="Детали"
           size="sm"
           @click="toggleDetails(item)"
         >
           <CIcon :name="item._toggled ? 'cilExpandUp' : 'cilExpandDown'" />
         </CButton>
         <CButton
           color="secondary"
           title="Подробнее"
           size="sm"
           :to="{ name: 'admin.transits.show', params: { id: item.id } }"
         >
           <CIcon name="cilOptions" />
         </CButton>
       </td>
     </template>
   </CDataTable>
   <CPagination
    :activePage="page"
    :pages="totalPages"
    v-on:update:activePage="changePage"
   />
  </CCard>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2';
import LoadingButton from '@/components/loading-button';

export default {
  name: 'TransitsList',
  components: {
    DatePicker,
    LoadingButton,
  },
  data() {
    return {
      list: [],
      filters: {
        sku: '',
        ownershipFrom: null,
        initiator: '',
        verified: null,
      },
      totalPages: 1,
      page: 1,
      isFiltersVisible: false,
      loading: false,
    }
  },
  mounted() {
    this.loadTransits()
  },
  computed: {
    listForTable() {
      return this.list.map(el => ({
        ...el,
        '_classes': !el.verified ? ['disabled-row'] : []
      }))
    },
    tableFields() {
      return [
        {key: 'initiators', label: 'Заказ|Документ', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'productOwner', label: 'В транзите у'},
        {key: 'daysInTransit', label: 'Дней в транзите'},
        {key: 'actions', label: '', _style: 'text-align:right;'}
      ]
    },
  },
  methods: {
    toggleDetails (item) {
      this.$set(this.list[item._index], '_toggled', !item._toggled)
    },
    async loadTransits(page = 1) {
      this.loading = true;
      try {
        const {data} = await this.$axios.request({url: 'api/transits', params: {page, ...this.filters}});
        this.page = page;
        this.list = data.items.map((el, ind) => ({ ...el, _index: ind }));
        this.totalPages = data.totalPages;
      } catch(e) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так'
        })
      } finally {
        this.loading = false;
      }
    },
    changePage(newPage) {
      this.loadTransits(newPage)
    },
    clearFilters() {
      this.filters = {
        sku: '',
        ownershipFrom: null
      }
      this.loadTransits();
    }
  }
}
</script>
